import React from "react"
import Layout from "../components/layout"
import MiscContent from "../layouts/MiscContent"
import { LayoutTitle } from "../styles/app.styles"
import MeetTeam from "../layouts/MeetTeam/meet-team.component"
import { graphql, useStaticQuery } from "gatsby"
import PartnersLogo from "../layouts/PartnersLogo"
import AboutHero from "../layouts/AboutHero/about-hero.component"
import PipelineHero from "../layouts/PipelineHero/pipeline-hero.component"
import PublicationsSlider from "../layouts/PublicationsSlider"
import PipelineTable from "../layouts/PipelineTable/pipeline-table.component"
import TeamHero from "../layouts/TeamHero"
import GridTeam from "../layouts/GridTeam/grid-team.component"
import MapMarkers from "../layouts/MapMarkers/map-markers.component"
import InfoGrid from "../layouts/InfoGrid/info-grid.component"
import ProjectTable from "../layouts/ProjectTable/project-table.component"
import JoinTeam from "../layouts/JoinTeam/join-team.component"
import WhatsNext from "../components/whats-next/whats-next.component"
import CaseStudiesHero from "../layouts/CaseStudiesHero/case-studies-hero.component"

const Layouts = () => {
  const staticQuery = useStaticQuery(graphql`
    query {
      employeeImg: file(relativePath: { eq: "employeeImg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      moleculaImage: file(relativePath: { eq: "molecule_bg_team.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      logoSlider1: file(relativePath: { eq: "arpeggioLogo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      logoSlider2: file(relativePath: { eq: "BlueLogo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      logoSlider3: file(relativePath: { eq: "COVID_MoonshotLogo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      logoSlider4: file(relativePath: { eq: "NIHLogo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      logoSlider5: file(relativePath: { eq: "PfizerLogo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      logoSlider6: file(relativePath: { eq: "oxfordLogo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      logoSlider7: file(relativePath: { eq: "RedesignLogo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      moleculaAbout: file(relativePath: { eq: "moleculaAbout.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      moleculePipe: file(relativePath: { eq: "molecule_5.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      moleculeSpipe: file(relativePath: { eq: "smooth_molecule_5.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      imgPublications: file(relativePath: { eq: "imgPublication.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      moleculeTeam: file(relativePath: { eq: "molecule_home.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      moleculeCaseS: file(relativePath: { eq: "molecule_6.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      smoothMoleculeCaseS: file(relativePath: { eq: "smoothImgCaseS.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Layout
      variation="about"
      seo={{
        title: "Layouts - ONLY FOR DEV",
        metaRobotsNoindex: "noindex",
        metaRobotsNofollow: "nofollow",
      }}
    >
      <LayoutTitle>MiscContent</LayoutTitle>
      <MiscContent content={`<p>Some content Here</p>`} />
      <LayoutTitle>Pipeline Hero</LayoutTitle>
      <PipelineHero
        title="Meet our team of drug hunters, researchers, and engineers all working together to improve the way we do drug discovery."
        smoothImage={staticQuery.moleculeSpipe}
        image={staticQuery.moleculePipe}
      />
      <LayoutTitle>Pipeline Table</LayoutTitle>
      <PipelineTable
        title="The PostEra Pipeline"
        tableItems={[
          {
            indication: "COVID",
            phaseProgress: "75",
            partner: "Moonshot",
            rights: "IP - Free",
          },
          {
            indication: "COVID",
            phaseProgress: "25",
            partner: "Pfizer",
            rights: "Royalties + Milestones",
          },
          {
            indication: "Pandemic Preparedness",
            phaseProgress: "15",
            partner: "NIH",
            rights: "IP - Free",
          },
          {
            indication: "Oncology",
            phaseProgress: "10",
            partner: "Pfizer",
            rights: "Royalties + Milestones",
          },
          {
            indication: "Coming Soon",
            partner: "Internal",
            rights: "Wholly-Owned",
          },
          {
            indication: "Coming Soon",
            partner: "Internal",
            rights: "Wholly-Owned",
          },
          {
            indication: "Coming Soon",
            partner: "Internal",
            rights: "Wholly-Owned",
          },
        ]}
      />
      <LayoutTitle>Map Markers</LayoutTitle>
      <MapMarkers
        mapImage={staticQuery.worldMap}
        title="In March 2020 PostEra helped launch COVID Moonshot, which became the world’s largest open-science initiative to develop a COVID antiviral cure."
        description="With low and middle income economies limited in access to vaccines, Moonshot set out to develop patent-free compounds that would be readily accessible for all patients around the world."
        mapPoints={[
          {
            lat: 23.424076,
            lng: 53.847818,
            content:
              "University of North Carolina<br />United States<br /><span>Antiviral Assays</span>",
          },
          {
            lat: -11.202692,
            lng: 17.873887,
            content:
              "University of North Carolina<br />United States<br /><span>Antiviral Assays</span>",
          },
          {
            lat: -38.416097,
            lng: -63.616672,
            content:
              "University of North Carolina<br />United States<br /><span>Antiviral Assays</span>",
          },
        ]}
      />
      <LayoutTitle>Info Grid</LayoutTitle>
      <InfoGrid
        title={
          "PostEra’s machine learning drove the prioritization of crowdsourced ideas "
        }
        description={`Moonshot crowdsourced antiviral ideas, inspired by a fragment screen, from over 400 scientists around the world. 
          PostEra’s machine learning drove the prioritization of these ideas and designed the synthesis of all 2000 
          compounds made as part of COVID Moonshot as well as using our ML technology to develop new 
          <a href="/">chemotypes.</a>`}
      />
      <LayoutTitle>Project Table</LayoutTitle>
      <ProjectTable
        description={
          "In 18 months, the project progressed from a fragment screen to the nomination of Development Candidates ready for IND-enabling studies."
        }
        tableItems={[
          {
            property: "Protese Assay",
            targetRange: "IC50 < 50 nM",
            results: "40 nM",
          },
          {
            property: "Viral Replication",
            targetRange: "EC50 < 0.2 µM",
            results: "0.15 µM in A549 CPE",
          },
          {
            property: "PK-PD",
            targetRange: "Cmin > EC90 (Plaque deriction) for 24h",
            results: "Current Projected human doce ~220mg QD; 100mg BID",
          },
          {
            property: "Coronavirus Spectrum",
            targetRange:
              "SARS-CoV2 B1.1.7, 501.V2, B.1.1.248 variants essential",
            results: "Active against B1.1.7, 501.V2 in cellular assays",
          },
          {
            property: "Route of Administration",
            targetRange: "Oral",
            results: "BO = 45% in rat",
          },
          {
            property: "Solubility",
            targetRange: "> 5 ,g/mL, > 100 µM tolerable",
            results: "0.15 µM in A549 CPE",
          },
          {
            property: "Half-Life",
            targetRange: "ideally > = 8h(human) est from rat and dog",
            results: "Rat 2h, human predicted PK suffiente",
          },
          {
            property: "Safety",
            targetRange: `No significant proteae activity > 50% at 10µM (Nanosyn 61 protese panel)
                          Only reversible and monitorable toxicities (NOAEL > 30x Cmax)
                          No significant DDI - clean in 5 CYP$%) isoforms
                          Critical transportes check (e.g OATP)
                          hERG and NaV1.5 IC50 > 50 µM
                          No significant change in QTc
                          No Mutagenicity or teratogenicity risk`,
            results: `
                  Protease panel clean on analogues
                  Eurofins / CEREP 44 target panel clean
                  Cyp450: clean except 2A4 (3uM)
                  No hERG activity 
                  Live phase planned
                  Lead compounds are clean in AMES +/- S9`,
          },
        ]}
        whatsNext={[
          {
            title: "What´s next",
            items: [
              {
                title: "$11M",
                description:
                  "Moonshot attracted an $11M grant to move our patent-free compounds through IND-enabling studies and into clinical trials.",
              },
              {
                title: "$68M",
                description:
                  "PostEra and members of the Moonshot consortium successfully secured a $68M grant from the NIH to develop antivirals against other viruses of pandemic concern.",
              },
            ],
          },
        ]}
      />
      <LayoutTitle>CaseStudiesHero</LayoutTitle>
      <CaseStudiesHero
        title={
          "COVID Moonshot: PostEra’s machine learning platform in real world drug discovery"
        }
        topLabel="CASE <br /> STUDIES"
        image={staticQuery.moleculeCaseS}
        smoothImage={staticQuery.smoothMoleculeCaseS}
      />
      <LayoutTitle>MeetTeam</LayoutTitle>
      <MeetTeam
        title={"Meet the Team"}
        employees={[
          {
            name: "Jhon Doe 0",
            occupation: "Founder and CEO",
            image: staticQuery.employeeImg,
          },
          {
            name: "Jhon Doe 1",
            occupation: "Founder and CEO",
            image: staticQuery.employeeImg,
          },
          {
            name: "Jhon Doe 2",
            occupation: "Founder and CEO",
            image: staticQuery.employeeImg,
          },
          {
            name: "Jhon Doe 3",
            occupation: "Founder and CEO",
            image: staticQuery.employeeImg,
          },
          {
            name: "Jhon Doe 4",
            occupation: "Founder and CEO",
            image: staticQuery.employeeImg,
          },
        ]}
      />
      <LayoutTitle>PartnersSlider</LayoutTitle>
      <PartnersLogo
        moleculaImage={staticQuery.moleculaImage}
        logos={[
          {
            logo: staticQuery.logoSlider3,
          },
          {
            logo: staticQuery.logoSlider5,
          },
          {
            logo: staticQuery.logoSlider4,
          },
          {
            logo: staticQuery.logoSlider2,
          },
          {
            logo: staticQuery.logoSlider6,
          },
          {
            logo: staticQuery.logoSlider1,
          },
          {
            logo: staticQuery.logoSlider7,
          },
        ]}
        title={"Our Drug Discovery Partners"}
      />
      <LayoutTitle>AboutHero</LayoutTitle>
      <AboutHero
        txtLabel={"The<br>Problem"}
        extraText={"The discovery of a cure follows three stages"}
        steps={[
          {
            name: "Biology",
            description:
              "Identify relevant biological target(s) associated with the disease.",
          },
          {
            name: "Chemestry",
            description:
              "Develop safe and effective chemical compounds that can hit those targets.",
          },
          {
            name: "Medicine",
            description:
              "Develop these compounds into medicines by showing their effectiveness in the appropriate patients in clinical trials.",
          },
        ]}
        title={
          "Developing cures for diseases has a high rate of failure, is very time consuming, and very expensive."
        }
        moleculaImage={staticQuery.moleculaAbout}
      />
      <LayoutTitle>Publications Slider</LayoutTitle>
      <PublicationsSlider
        title={"Our Technology is not a black box"}
        description={
          "We’ve partnered with the world’s top pharmaceutical companies to validate our approach."
        }
        publications={[
          {
            title: "How do we train models that know what they don't know?",
            image: staticQuery.imgPublications,
            url: "url",
          },
          {
            title: "Title 2",
            image: staticQuery.imgPublications,
            url: "url 2",
          },
          {
            title: "Title 3",
            image: staticQuery.imgPublications,
            url: "url 3",
          },
          {
            title: "Title 4",
            image: staticQuery.imgPublications,
            url: "url 4",
          },
        ]}
      />
      <LayoutTitle>TeamHero</LayoutTitle>
      <TeamHero
        image={staticQuery.moleculeTeam}
        title={
          "Meet our team of drug hunters, researchers, and engineers all working together to improve the way we do drug discovery."
        }
      />
      <LayoutTitle>GrideTeam</LayoutTitle>
      <GridTeam
        team={[
          {
            name: "Jhon Doe 2",
            occupation: "Founder and CEO",
            image: staticQuery.employeeImg,
          },
          {
            name: "Jhon Doe 3",
            occupation: "Founder and CEO",
            image: staticQuery.employeeImg,
          },
          {
            name: "Jhon Doe 4",
            occupation: "Founder and CEO",
            image: staticQuery.employeeImg,
          },
          {
            name: "Jhon Doe 5",
            occupation: "Founder and CEO",
            image: staticQuery.employeeImg,
          },
          {
            name: "Jhon Doe 6",
            occupation: "Founder and CEO",
            image: staticQuery.employeeImg,
          },
          {
            name: "Jhon Doe 7",
            occupation: "Founder and CEO",
            image: staticQuery.employeeImg,
          },
          {
            name: "Jhon Doe 8",
            occupation: "Founder and CEO",
            image: staticQuery.employeeImg,
          },
          {
            name: "Jhon Doe 9",
            occupation: "Founder and CEO",
            image: staticQuery.employeeImg,
          },
        ]}
      />
      <LayoutTitle>JoinTeam</LayoutTitle>
      <JoinTeam title={"Join our team"} />
      <LayoutTitle>AboutHero-Discovery</LayoutTitle>
      <AboutHero
        withMolecules={false}
        txtLabel={"CO-DISCOVERY"}
        txtButton={"Talk to us about Co-Discovery"}
        extraText={"Here’s how we typically work"}
        steps={[
          {
            description:
              "Identify relevant biological target(s) associated with the disease.",
          },
          {
            description:
              "Develop safe and effective chemical compounds that can hit those targets.",
          },
          {
            description:
              "Develop these compounds into medicines by showing their effectiveness in the appropriate patients in clinical trials.",
          },
        ]}
        title={
          "We partner with organizations to develop small molecule drug candidates."
        }
        moleculaImage={staticQuery.moleculaAbout}
      />
    </Layout>
  )
}

export default Layouts
